import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import { PROCUCT_ID_CC, PROCUCT_ID_LOAN } from '../constants/appConstant'

/**
* Single page (/:slug)
*
* This file renders a single page and loads all the content.
*
*/
const Page = ({ data, location, pageContext }) => {

    return (
        <>{
            pageContext.productId == PROCUCT_ID_CC
                ? <MetaData
                    data={{}}
                    location={location}
                    type="website"
                    title={`${pageContext.title}: Check Eligibility & Apply Online| Chqbook.com`}
                    description={`Apply online for ${pageContext.title}. Check features, benefits, offers, reward points and eligibility. Get instant approval!`}
                />
                : pageContext.productId == PROCUCT_ID_LOAN
                    ? <MetaData
                        data={{}}
                        location={location}
                        type="website"
                        title={`${pageContext.title}: Check Eligibility & Apply for Business and Personal Loan `}
                        description={`Get ${pageContext.title} from Chqbook. Speedy disbursal and attractive interest rates.`}
                    />
                    : <MetaData
                        data={{}}
                        location={location}
                        type="website"
                        title={`${pageContext.title}`}
                        description={`${pageContext.description}`}
                    />
        }
            <div>
                {pageContext.title}
            </div>
        </>
    )
}

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }),
    }),
    location: PropTypes.object.isRequired,
}

export default Page
